<template>
    <div>
        <header-portrate />

    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />
        <div class="px-3 py-2" style="direction:rtl;width:500px;margin:auto">
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="اسم المستخدم *"
                        v-model="user.username"
                        readonly
                        >{{ user.username }}</v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="الاسم الكامل *"
                        v-model="user.full_name"
                        >{{ user.full_name }}</v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="كلمة المرور *"
                        v-model="password"
                        type="password"
                        ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="$store.state.group_id == 1">
                    <v-text-field
                        label="نسبة التحصيل "
                        v-model="user.vperc"
                        >{{ user.vperc }}</v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="user.group_id"
                        :items="perms"
                        label="مجموعة الصلاحيات"
                    ></v-select>
                </v-col>
                <div style="color:red;font-size:.7em">اذا لم تقم باختيار المجموعة ستبقى الصلاحيات الحالية كما هي</div>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn class="btn-sm btn success" @click="updateUser()">{{lang.save}}</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default{
  components: { BreadCrumbs, TabsComp, HeaderPortrate, Footer },
    data() {
        return {
            user: {},
            group_id: 0,
            password: '',
            perms:[
                {
                    text: 'موظف كاشير',
                    value: 3,
                },
                {
                    text: 'محاسب',
                    value: 2,
                },
                {
                    text: 'ادارة عامة',
                    value: 1,
                },
                {
                    text: 'الموردين',
                    value: 4,
                },
                {
                    text: 'مندوب',
                    value: 5,
                },
            ],
            
        }
    },
    computed: {
       
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items:function () {
            return {
                text: this.lang.users,
                disabled: true,
                to: '/settings/users-settings',
            }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    },
    methods:{
        getUsers(){
            const post = new FormData();
            post.append("type" , 'getUsers');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[userid]',this.$route.params.id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    // console.log(res);
                    this.user = res.results.data.results[0];
                    this.group_id = res.results.data.results[0].group_id;
                }
            )
        },
        updateUser() {
            const post = new FormData();
            post.append('type', 'updateUser');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',this.$route.params.id);
            post.append('data[full_name]',this.user.full_name);
            post.append('data[password]',this.password);
            post.append('data[group_id]',this.user.group_id);
            post.append('data[vperc]',this.user.vperc);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                
                this.$router.push({path: '/settings/users-settings'})
            })
        }
    },
    created() {
        this.getUsers();
        
    },
}
</script>